<template>
    <Navbar :user="user" />
  <div class="container" v-if="!isLoading">
    <div class="section-title">
      <h2>Add Test Question</h2>
    </div>
    <div class="contact-form">
      <form @submit.prevent="editOption" id="contactForm" novalidate="true">
        <div class="row text-start">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label for="name">Title</label>
                    <input type="text" v-model="form.title" class="form-control"/>
                    <div v-if="errors.title" class="text-small text-danger text-start">{{ errors.title[0] }}</div>
                </div>
          </div>
          <div class="col-lg-12 col-md-12 text-center">
            <button type="submit" class="btn default-btn" disabled v-if="submitting">
              <span class="label">{{ value }}</span>
            </button>
            <button type="submit" class="default-btn btn" style="pointer-events: all; cursor: pointer" v-else>
              <span class="label">Save Changes</span>
            </button>
            
          </div>
        </div>
      </form>
    </div>
  </div>
  <div v-else>
      <Loading />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Loading from '@/components/Loading.vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import axios from 'axios'
export default {
  components: { Navbar,Loading },
    data(){
        return{
          isLoading: true,
          submitting: false,
          values: [],
          form:{
            title: '',
          },
          user: {},
          textFields: [],
          editor: ClassicEditor,
          token: localStorage.getItem('user_data'),
          errors: {}
            
        }
    },
    methods: {
    editOption(){
      this.submitting = true
      this.value = 'Please Wait...'
      let id = this.$route.params.id
      let questionId = this.$route.params.question
      axios.post('https://apitraining.vipawaworks.com/api/quiz_question_option/update/' + questionId,this.form).then(response =>{
        response.data
        this.$router.push({ name: 'TrainerQuestionDetails', params: {id: id}})
      }).catch((errors) => {
          this.errors = errors.response.data.errors
          this.submitting = false
      })
    },
    getQuestion(){
      let questionId = this.$route.params.question
      axios.get('https://apitraining.vipawaworks.com/api/quiz_question_option/profile/' + questionId).then(response => {
        this.form = response.data
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    getUser(){
      axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
        this.user = response.data
      }).catch(errors => {
        if (errors.response.status === 401) {
          this.$router.push({ name: 'Login'})
        }
      }).finally(() => {
        setTimeout(function () {
            this.isLoading =  false
        }.bind(this), 500);
      })
    },
  },
  created(){
    document.title = `Create Question - Training`
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
    this.getUser() 
    this.getQuestion()
  }

}
</script>

<style>
.text-fields-row {
  display: flex;
}
</style>